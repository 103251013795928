"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.variablesUsedInFlow = exports.missingVariablesMessage = exports.isStepMissingCustomVariables = exports.isTextMissingCustomVariables = exports.getTemplateQueryParamsFromVariables = exports.getCustomVariablesQueryString = exports.getCustomValuesQueryString = exports.getCustomValuesFromQuery = exports.withCustomVariables = void 0;
var TEMPLATE_VALUE = 'INSERT_VALUE_HERE';
// Matches e.g. {{ my_custom_variable }}
var CUSTOM_VARIABLE_SYNTAX_REGEX = /{{[ ]*([a-z_\-0-9]+)[ ]*}}/dgi;
// Matches e.g. variable.my_custom_variable
var CUSTOM_VARIABLE_QUERY_REGEX = /^variable.([a-z_\-0-9]+)$/i;
// This function parses text for custom variables and replaces matching syntax
// with provided custom values or the corresponding variables' fallback values.
// Along with the resulting string, it returns a set of used variables as well
// as missing variables referenced in text but not available on the account.
function withCustomVariablesVerbose(text, variables, values) {
    var _a;
    if (values === void 0) { values = {}; }
    if (!variables)
        variables = {};
    var used = new Set();
    var missing = new Set();
    var pos = 0;
    var match = null;
    var result = '';
    // Find each instance of matching syntax
    while ((match = CUSTOM_VARIABLE_SYNTAX_REGEX.exec(text))) {
        var name_1 = match[1];
        var exists = !!variables[name_1];
        // Determine whether this is an allowed custom variable
        if (exists)
            used.add(name_1);
        else
            missing.add(name_1);
        // Determine the value to insert, using a fallback if no custom value is
        // passed or empty string if the variable is not allowed. The latter seems
        // a bit better for the viewer experience if there's a missing variable...
        var valueProvided = !!values[name_1] && values[name_1] !== TEMPLATE_VALUE
            ? values[name_1]
            : (_a = variables[name_1]) === null || _a === void 0 ? void 0 : _a.fallback;
        var value = exists ? valueProvided : '';
        // Append the substring of text between the previous match and this match to
        // our result, as well as the appropriate variable value.
        var _b = match.indices[0], start = _b[0], end = _b[1];
        result += text.slice(pos, start);
        result += value;
        pos = end;
    }
    // Append the substring of text after the final match to our result
    result += text.slice(pos);
    return { result: result, used: used, missing: missing };
}
function withCustomVariables(text, variables, values) {
    return withCustomVariablesVerbose(text, variables, values).result;
}
exports.withCustomVariables = withCustomVariables;
function getCustomValuesFromQuery(query) {
    var customValues = {};
    for (var _i = 0, _a = Object.entries(query); _i < _a.length; _i++) {
        var _b = _a[_i], k = _b[0], v = _b[1];
        var value = Array.isArray(v) ? v[v.length - 1] : v;
        if (!value)
            continue;
        var match = CUSTOM_VARIABLE_QUERY_REGEX.exec(k);
        if (!match)
            continue;
        var name_2 = match[1];
        customValues[name_2] = value;
    }
    return customValues;
}
exports.getCustomValuesFromQuery = getCustomValuesFromQuery;
function getCustomValuesQueryString(query, startingChar) {
    if (query === void 0) { query = {}; }
    if (startingChar === void 0) { startingChar = '?'; }
    var customValues = [];
    for (var _i = 0, _a = Object.entries(query); _i < _a.length; _i++) {
        var _b = _a[_i], k = _b[0], v = _b[1];
        var value = Array.isArray(v) ? v[v.length - 1] : v;
        if (value && CUSTOM_VARIABLE_QUERY_REGEX.exec(k))
            customValues.push([k, value]);
    }
    return customValues.length
        ? "".concat(startingChar).concat(customValues.map(function (_a) {
            var k = _a[0], v = _a[1];
            return "".concat(k, "=").concat(v);
        }).join('&'))
        : '';
}
exports.getCustomValuesQueryString = getCustomValuesQueryString;
function getCustomVariablesQueryString(variables) {
    var keys = Object.keys(variables !== null && variables !== void 0 ? variables : {});
    return keys.length
        ? "?".concat(keys.map(function (k) { return "variable.".concat(k, "=").concat(TEMPLATE_VALUE); }).join('&'))
        : '';
}
exports.getCustomVariablesQueryString = getCustomVariablesQueryString;
function getTemplateQueryParamsFromVariables(variables) {
    if (!variables)
        return {};
    return Object.fromEntries(Object.keys(variables).map(function (name) { return ["variable.".concat(name), TEMPLATE_VALUE]; }));
}
exports.getTemplateQueryParamsFromVariables = getTemplateQueryParamsFromVariables;
function isTextMissingCustomVariables(text, variables) {
    return (text &&
        !!withCustomVariablesVerbose(text, variables, undefined).missing.size);
}
exports.isTextMissingCustomVariables = isTextMissingCustomVariables;
function isStepMissingCustomVariables(step, variables) {
    return getStepFieldsWithVariables(step).some(function (t) {
        return isTextMissingCustomVariables(t, variables);
    });
}
exports.isStepMissingCustomVariables = isStepMissingCustomVariables;
function missingVariablesMessage(name) {
    return "".concat(name, " is attempting to reference variables that do not exist.");
}
exports.missingVariablesMessage = missingVariablesMessage;
function variablesUsedInFlow(flow) {
    var used = new Set();
    for (var _i = 0, _a = flow.steps; _i < _a.length; _i++) {
        var step = _a[_i];
        for (var _b = 0, _c = getStepFieldsWithVariables(step); _b < _c.length; _b++) {
            var text = _c[_b];
            var usedVariables = Array.from(withCustomVariablesVerbose(text !== null && text !== void 0 ? text : '', flow.customVariables).used);
            for (var _d = 0, usedVariables_1 = usedVariables; _d < usedVariables_1.length; _d++) {
                var variable = usedVariables_1[_d];
                used.add(variable);
            }
        }
    }
    for (var _e = 0, _f = [flow.name, flow.description]; _e < _f.length; _e++) {
        var text = _f[_e];
        var usedVariables = Array.from(withCustomVariablesVerbose(text !== null && text !== void 0 ? text : '', flow.customVariables).used);
        for (var _g = 0, usedVariables_2 = usedVariables; _g < usedVariables_2.length; _g++) {
            var variable = usedVariables_2[_g];
            used.add(variable);
        }
    }
    return used;
}
exports.variablesUsedInFlow = variablesUsedInFlow;
function getStepFieldsWithVariables(step) {
    return __spreadArray(__spreadArray([
        step.title,
        step.subtitle,
        step.buttonText
    ], step.hotspots.map(function (h) { return h.label; }), true), (step.paths ? step.paths.map(function (p) { return p.buttonText; }) : []), true);
}
