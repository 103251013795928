"use strict";
exports.__esModule = true;
exports.DEFAULT_ZOOMED_IN_LEVEL = exports.STRIPE_IDS = exports.stepPropsThatAffectViewerRendering = exports.flowPropsThatAffectViewerRendering = exports.DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS = exports.BROWSER_BAR_OR_CAPTION_HEIGHT = void 0;
exports.BROWSER_BAR_OR_CAPTION_HEIGHT = 41;
exports.DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS = 14;
exports.flowPropsThatAffectViewerRendering = [
    'aspectRatio',
    'belongsToTeam',
    'font',
];
exports.stepPropsThatAffectViewerRendering = [
    'blur',
    'buttonColor',
    'buttonText',
    'buttonTextColor',
    'duration',
    'endTimeFrac',
    'hotspots',
    'muted',
    'panAndZoom',
    'paths',
    'playbackRate',
    'size',
    'startTimeFrac',
    'streamUrl',
    'subtitle',
    'theme',
    'title',
    'url',
    'videoThumbnailUrl',
];
exports.STRIPE_IDS = {
    test: {
        proLegacy: {
            id: 'NOT_SET',
            monthly: 'NOT_SET',
            yearly: 'NOT_SET',
            billingPortalConfigId: 'bpc_1MoTp1CDgCbpE4wjLGzZj4i7'
        },
        pro: {
            id: 'prod_JgVW6XyJFn1GOp',
            monthly: 'price_1LAOgiCDgCbpE4wjE4ZS2MNv',
            yearly: 'price_1MRjaCCDgCbpE4wj9BKR1D9t',
            billingPortalConfigId: 'bpc_1MoTp1CDgCbpE4wjLGzZj4i7'
        },
        growth: {
            id: 'prod_NC4dyvUBYsckAV',
            monthly: 'price_1N1iE6CDgCbpE4wjAPf2kbTK',
            yearly: 'price_1MRgU0CDgCbpE4wj3IRWZ9Qc',
            euroMonthly: 'NOT_SET',
            billingPortalConfigId: 'bpc_1MoTs4CDgCbpE4wjDM6EtzZy'
        }
    },
    live: {
        proLegacy: {
            id: 'prod_LoFjjMbsRubmhN',
            monthly: 'NOT_SET',
            yearly: 'price_1LAeIkCDgCbpE4wj878If8nn',
            billingPortalConfigId: 'bpc_1MoTvVCDgCbpE4wjD6wkZ5Zx'
        },
        pro: {
            id: 'prod_LDHiTE6EefMd0q',
            monthly: 'price_1KWr9GCDgCbpE4wjm9BtSM6J',
            yearly: 'price_1MRjxbCDgCbpE4wjPDG4ZqPv',
            billingPortalConfigId: 'bpc_1MoTvVCDgCbpE4wjD6wkZ5Zx'
        },
        growth: {
            id: 'prod_NBsfBq7KHd8Lub',
            monthly: 'price_1MRUuUCDgCbpE4wjuWjHDUc7',
            yearly: 'price_1MRUuUCDgCbpE4wjPn4qWicR',
            euroMonthly: 'price_1MSLtZCDgCbpE4wjHWwtB3NH',
            billingPortalConfigId: 'bpc_1MoTtyCDgCbpE4wjkNE1hQZo'
        }
    }
};
exports.DEFAULT_ZOOMED_IN_LEVEL = 1.5;
