"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.getStripeIds = void 0;
var constants_1 = require("../constants");
var getStripeIds = function (secretKey, includePromoPrice) {
    if (includePromoPrice === void 0) { includePromoPrice = false; }
    if (includePromoPrice) {
        return secretKey.startsWith('sk_live')
            ? __assign(__assign({}, constants_1.STRIPE_IDS.live), { pro: {
                    id: constants_1.STRIPE_IDS.live.pro.id,
                    monthly: 'price_1Mgcm0CDgCbpE4wjNUbHwQgg',
                    yearly: 'price_1MgcmQCDgCbpE4wjOaA09tni',
                    billingPortalConfigId: 'bpc_1MgiBRCDgCbpE4wjy2JfTjQZ'
                } }) : __assign(__assign({}, constants_1.STRIPE_IDS.test), { pro: {
                id: constants_1.STRIPE_IDS.test.pro.id,
                monthly: 'price_1MgcqqCDgCbpE4wjAGOYLAzz',
                yearly: 'price_1Mgcr2CDgCbpE4wjRIlTI2ov',
                billingPortalConfigId: 'bpc_1MgfSJCDgCbpE4wjnXaGx53k'
            } });
    }
    else {
        return secretKey.startsWith('sk_live') ? constants_1.STRIPE_IDS.live : constants_1.STRIPE_IDS.test;
    }
};
exports.getStripeIds = getStripeIds;
