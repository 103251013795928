"use strict";
exports.__esModule = true;
exports.getEmbedCode = void 0;
var constants_1 = require("../constants");
var customVariables_1 = require("./customVariables");
function getEmbedCode(_a) {
    var flowId = _a.flowId, customLinkId = _a.customLinkId, aspectRatio = _a.aspectRatio, queryParams = _a.queryParams, baseUrl = _a.baseUrl;
    if (isNaN(aspectRatio))
        aspectRatio = 2 / 3;
    var embedUrl = "".concat(baseUrl, "/").concat(flowId).concat(customLinkId ? "/".concat(customLinkId) : '', "?embed").concat((0, customVariables_1.getCustomValuesQueryString)(queryParams, '&'));
    var paddingBottom = "calc(".concat(aspectRatio * 100, "% + ").concat(constants_1.BROWSER_BAR_OR_CAPTION_HEIGHT, "px)");
    return "<div style=\"position: relative; padding-bottom: ".concat(paddingBottom, "; height: 0;\"><iframe src=\"").concat(embedUrl, "\" frameborder=\"0\" loading=\"lazy\" webkitallowfullscreen mozallowfullscreen allowfullscreen style=\"position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;\"></iframe></div>");
}
exports.getEmbedCode = getEmbedCode;
